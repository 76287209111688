import React from "react"

import Header from "../components/Headers/EmptyHeader"

import Layout from "../components/Layouts/secondaryPage"
import ProgressBar from "../components/ProgressBar"
import Success from "../components/Success"

export default function Page() {
  return (
    <Layout>
      <Header />
      <ProgressBar caption="100%" width="100%" />
      <Success />
    </Layout>
  )
}
