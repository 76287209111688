import React from "react"

import Container from "@ecom/ui/components/Container"

import * as styles from "./progressBar.module.scss"

type Props = {
  caption: string
  width: string
}

export default function ProgressBar({ caption, width }: Props) {
  return (
    <div className={styles.root}>
      <Container className={styles.container}>
        <div className={styles.completedPart} style={{ width }} />
        <p className={styles.title}>{caption}</p>
      </Container>
    </div>
  )
}
